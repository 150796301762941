import React, { useState } from 'react';
import Webcam from 'react-webcam';
import './App.css'; // Import your CSS file for styling

const App = () => {
  const webcamRef = React.useRef(null);
  const [cameraInfo, setCameraInfo] = useState(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Do something with the captured image (e.g., display, save, etc.)
    console.log(imageSrc);
  };

  const getCameraDetails = () => {
    if (webcamRef && webcamRef.current && webcamRef.current.video) {
      const camera = webcamRef.current.video;
      console.log('Camera Details:', camera);
      // Set camera information to state
      setCameraInfo({
        name: camera.label || 'Unknown',
        resolution: `${camera.videoWidth}x${camera.videoHeight}`,
      });
    } else {
      console.log('Camera details not available.');
    }
  };

  return (
    <div className="app-container">
      <h1 className="app-heading">Your camera output</h1>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        className="webcam-preview" // Add your styling class here for the webcam preview
      />

      <br />
      {/* <button onClick={capture}>Capture Photo</button>
      <button onClick={getCameraDetails}>Show Camera Details</button>
      {cameraInfo && (
        <div className="camera-info">
          <p>Camera Name: {cameraInfo.name}</p>
          <p>Resolution: {cameraInfo.resolution}</p>
        </div>
      )} */}
    </div>
  );
};

export default App;
